import { AxiosError } from "axios";
import { PlanFormProps } from "components/Forms/interface/PlanForm";
import { toast } from "react-toastify";
import apiClient from "services/axios/CrmApi";
import { PaginatedResponse, Plan } from "utils/interfaces";
import { ApiError } from "utils/interfaces/responses/error";
import { ApiResponse } from "utils/interfaces/responses/sucess";

export default class PlanController {
  static defaultPaginatedResponse: PaginatedResponse<Plan> = {
    current_page: 1,
    data: [],
    from: 0,
    last_page: 1,
    per_page: 0,
    to: 0,
    total: 0,
  };

  static async getPlanPage(id: number): Promise<PaginatedResponse<Plan>> {
    return toast
      .promise(
        apiClient.get<ApiResponse<PaginatedResponse<Plan>>>(
          `/plans/index?page=${id}`
        ),
        {
          pending: "Atualizando lista de Planos...",
          error: {
            render({ data }: { data: AxiosError<ApiError> }) {
              return `${data.response?.data.message}`;
            },
          },
        }
      )
      .then(({ data }) => {
        if (data.data) {
          return data.data as PaginatedResponse<Plan>;
        } else {
          return this.defaultPaginatedResponse;
        }
      })
      .catch((error) => {
        console.log(error);
        return this.defaultPaginatedResponse;
      });
  }

  static async listPlans(ignore_cache: boolean = false): Promise<Plan[]> {
    const cachedPlans = sessionStorage.getItem("plans");

    if (!ignore_cache && cachedPlans) {
      return JSON.parse(cachedPlans);
    }

    return toast
      .promise(apiClient.get<ApiResponse<Plan[]>>(`/plans/list`), {
        pending: "Obtendo Planos",
        error: {
          render({ data }: { data: AxiosError<ApiError> }) {
            return `${data.response?.data.message}`;
          },
        },
      })

      .then(({ data }) => {
        if (data.data) {
          sessionStorage.setItem("plans", JSON.stringify(data.data));
          return data.data as Plan[];
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  }

  static async store(planForm: PlanFormProps) {
    return toast
      .promise(apiClient.post(`/plans/store`, planForm), {
        pending: "Cadastrando Planos",

        error: {
          render({ data }: { data: AxiosError<ApiError> }) {
            return `${data.response?.data.message}`;
          },
        },
      })
      .then(async () => await this.listPlans(true))
      .catch((error) => {
        console.log(error);
      });
  }

  static async put(planForm: PlanFormProps) {
    return toast
      .promise(apiClient.put(`/plans/update/${planForm.id}`, planForm), {
        pending: "Cadastrando Planos",

        error: {
          render({ data }: { data: AxiosError<ApiError> }) {
            return `${data.response?.data.message}`;
          },
        },
      })
      .then(async () => await this.listPlans(true))
      .catch((error) => {
        console.log(error);
      });
  }

  static async delete(id: string | number) {
    return toast
      .promise(apiClient.delete(`/plans/delete/${id}`), {
        pending: "Removendo Plano",
        error: {
          render({ data }: { data: AxiosError<ApiError> }) {
            return `${data.response?.data.message}`;
          },
        },
      })
      .then(async () => await this.listPlans(true))
      .catch((error) => {
        console.log(error);
      });
  }

  static async enable(id: string | number) {
    return toast
      .promise(apiClient.patch(`/plans/enable/${id}`), {
        pending: "Ativando Plano",
        error: {
          render({ data }: { data: AxiosError<ApiError> }) {
            return `${data.response?.data.message}`;
          },
        },
      })
      .then(async () => await this.listPlans(true))
      .catch((error) => {
        console.log(error);
      });
  }
}
