import React, { useEffect, useState } from "react";
import TableWithExpandableRows, { IdForm } from "components/Table/ClientTable";
import { ClientProvider } from "contexts/ClientListProvider";

const ClientList: React.FC = () => {
  return (
    <div className="card shadow-xl w-full bg-base-100">
      <div className="card-body text-base-content">
        <h2 className="card-title mb-1">
          <div className="mr-auto">Clientes</div>
          <label
            htmlFor={IdForm}
            className="btn btn-accent text-accent-content"
          >
            Adicionar Cliente
          </label>
        </h2>
        <ClientProvider>
          <TableWithExpandableRows />
        </ClientProvider>
      </div>
    </div>
  );
};

export default ClientList;
