import ClientController from "Controllers/ClientController";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import CrmApi from "services/axios/CrmApi";
import { ClientCache } from "utils/ClientCache";
import { formatDocument } from "utils/DTOs/Documents";
import { Client } from "utils/interfaces";

export const getModalRef = () =>
  document.getElementById("search_modal_1") as HTMLDialogElement;

interface searchFromModel {
  query: string;
}

const SearchModal = ({}) => {
  const { register, watch } = useForm<searchFromModel>();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<Client[]>([]);
  const [cachedClients, setCachedClients] = useState<Client[]>([]);

  const watchQuery = watch("query", "");

  useEffect(() => {
    const handler = setTimeout(() => {
      setQuery(watchQuery);
    }, 500);

    return () => clearTimeout(handler);
  }, [watchQuery]);

  const search = async () => setResults(await ClientController.search(query));

  useEffect(() => {
    if (query) {
      search();
    }
  }, [query]);

  const updateCache = () => {
    setCachedClients(ClientCache.getRecentItems());
  };

  return (
    <dialog id="search_modal_1" className="modal">
      <div className="modal-box p-0">
        {/* Search Input */}
        <div className="form-control flex-row items-center rounded-box p-2 px-5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            className="text-base-content/70"
            fontSize="18"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <g
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            >
              <circle cx="11" cy="11" r="8" />
              <path d="m21 21l-4.3-4.3" />
            </g>
          </svg>
          <input
            placeholder="Pesquisa"
            className="input w-full text-base focus:border-transparent focus:outline-0 input-sm focus:outline-offset-0"
            defaultValue=""
            {...register("query")}
            onFocus={updateCache}
          />
          <button
            aria-label="Close search modal"
            className="btn gap-2 btn-sm btn-circle btn-ghost"
            onClick={() => getModalRef().close()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              fontSize="16"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M18 6L6 18M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        {/* Actions */}
        <div className="border-t border-base-content/10">
          <ul className="menu">
            {!query && (
              <>
                <li className="menu-title select-none">Ações</li>
                <li>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="img"
                      fontSize="18"
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 10v6m-3-3h6m5 7a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-7.9a2 2 0 0 1-1.69-.9L9.6 3.9A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2Z"
                      />
                    </svg>
                    <p className="grow text-sm">Adicionar novo clinte</p>
                  </div>
                </li>
                <li>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="img"
                      fontSize="18"
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                    >
                      <g
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      >
                        <path d="M20 17a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3.9a2 2 0 0 1-1.69-.9l-.81-1.2a2 2 0 0 0-1.67-.9H8a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2Z" />
                        <path d="M2 8v11a2 2 0 0 0 2 2h14" />
                      </g>
                    </svg>
                    <p className="grow text-sm">Ver lista de clientes</p>
                  </div>
                </li>
                <li className="menu-title select-none">Recentes</li>
                {ClientCache.getRecentItems().map((clt) => (
                  <SearchCard client={clt} />
                ))}
              </>
            )}
            {query && (
              <>
                <li className="menu-title select-none">Clientes</li>
                {results.map((clt) => (
                  <SearchCard client={clt} />
                ))}
              </>
            )}
          </ul>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button onClick={() => getModalRef().close()}>close</button>
      </form>
    </dialog>
  );
};

const SearchCard: React.FC<{ client: Client }> = ({ client }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    getModalRef().close();
    navigate(`/clients/${client.id}`);
  };

  return (
    <div className="mt-1 flex">
      <div
        role="alert"
        className="alert flex justify-between items-center space-x-3 p-4 bg-base-100/40 hover:bg-base-300/60 rounded-md shadow"
      >
        {/* Conteúdo */}
        <div>
          <h3 className="font-bold text-base-content">{client.name}</h3>
          <div className="text-xs text-base-content/80">
            {client.fantasy_name}.
            {client.document && formatDocument(client.document)}
          </div>
        </div>

        {/* Botão */}
        <button
          onClick={handleClick}
          className="btn btn-sm bg-blue-500 text-white rounded px-3 py-1 hover:bg-blue-600"
        >
          Ver mais
        </button>
      </div>
    </div>
  );
};

export default SearchModal;
