import Dropdown from "components/Dropdown/dropdown";
import { modalRef } from "components/Modal/clientProduct";
import { ClientProductContext } from "contexts/ClientProductProvider";
import ProductController from "Controllers/ProductController";
import React, { useContext } from "react";
import { transformProdutToProductFormModel } from "utils/DTOs/Product";
import { getProductTypeDescription } from "utils/Enums/ProductType";
import { Product } from "utils/interfaces/forms/productForm";

interface RowData {
  row: Product;
}

const ClientProductRow: React.FC<RowData> = ({ row }) => {
  const { setEditableProduct, updateProducts, getProduct } =
    useContext(ClientProductContext)!;

  const editPlan = async () => {
    setEditableProduct(undefined);
    modalRef().showModal();
    setEditableProduct(
      transformProdutToProductFormModel(await getProduct(row.id))
    );
  };

  const deletePlan = async () => {
    await ProductController.disable(row.id);
    updateProducts();
  };

  const enablePlan = async () => {
    await ProductController.enable(row.id);
    updateProducts();
  };

  return (
    <React.Fragment key={row.id}>
      <tr className="hover cursor-pointer">
        <th>{row.id}</th>
        <td className="whitespace-nowrap">
          {getProductTypeDescription(row.type)}
        </td>
        <td>{row.domain_id}</td>
        <td>{row.url}</td>
        <td>
          {row.status ? (
            <span className="rounded-badge border border-success/50 bg-success/5 px-3 py-1 text-xs font-medium text-success">
              Ativo
            </span>
          ) : (
            <span className="rounded-badge border border-error/50 bg-error/5 px-3 py-1 text-xs font-medium text-error">
              Inativo
            </span>
          )}
        </td>
        <td className="py-0">
          <Dropdown title="Ações">
            <ul>
              <li onClick={editPlan}>
                <a>Editar</a>
              </li>
              {row.status ? (
                <li onClick={deletePlan}>
                  <a>Desativar</a>
                </li>
              ) : (
                <li onClick={enablePlan}>
                  <a>Ativar</a>
                </li>
              )}
            </ul>
          </Dropdown>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default ClientProductRow;
