import { Navigate } from "react-router-dom";
import Button from "components/Button/Button";
import Checkbox from "components/Checkbox/Checkbox";
import { AuthContext } from "contexts/AuthProvider";
import React, { useContext, useState, ChangeEvent } from "react";
import NetInoveLogo from "./assets/logo_neti9.png";
import { SubmitHandler, useForm } from "react-hook-form";
import TextInput from "components/Forms/Input/TextInput";
import LoadingScreen from "views/Layouts/Loading";

interface LoginForm {
  password: string;
  email: string;
}

const Login: React.FC = () => {
  const { login, isLoggedIn, loadingCrendetials } = useContext(AuthContext);
  const [remember, setRemember] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>({});

  const onSubmit: SubmitHandler<LoginForm> = (data) => {
    login(data);
  };

  if (loadingCrendetials === true) {
    return <LoadingScreen />;
  }

  if (isLoggedIn === true) {
    return <Navigate to="/" />;
  }

  return (
    <div className="flex justify-center items-center h-full bg-neutral">
      <div className="py-9 px-16 bg-base-300 ">
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <h4 className="text-center">Consig CRM</h4>
            </div>
          </div>
          <div className="card-content">
            <div className="card-body">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col items-end"
              >
                <TextInput
                  label="Email"
                  error={errors.email}
                  {...register("email", { required: true })}
                />
                <TextInput
                  type="password"
                  label="Senha"
                  error={errors.password}
                  {...register("password", { required: true })}
                />
                <Checkbox
                  title="Manter logado"
                  color="warning"
                  checked={remember}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setRemember(!remember)
                  }
                />
                <button className="btn btn-wide btn-neutral">Login</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-consig flex justify-center p-16">
        <img
          className="img-fluid h-[290px]"
          src={NetInoveLogo}
          alt="CRM Netinove"
        />
      </div>
    </div>
  );
};

export default Login;
