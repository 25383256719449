import Select from "components/Forms/Input/Select";
import TextInput from "components/Forms/Input/TextInput";
import { ClientProductContext } from "contexts/ClientProductProvider";
import ProductController from "Controllers/ProductController";
import { useContext, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { transformProdutToProductFormModel } from "utils/DTOs/Product";
import { getProductValueLabel, ProductType } from "utils/Enums/ProductType";
import { ProductFormModel } from "utils/interfaces/forms/productForm";

export const modalRef = () =>
  document.getElementById("ProductModal") as HTMLDialogElement;

interface Props {}

export const ProductModal: React.FC<Props> = ({}) => {
  const defaultFormValues: ProductFormModel = {
    client_id: undefined,
    domain_id: "",
    id: undefined,
    status: "true",
    type: "0",
    url: "",
    consig: {
      devices_quantity: "",
      devices_quantity_adm: "",
      devices_quantity_wholesale: "",
      gps_activation_date: "",
      vehicles_quantity: "",
    },
  };
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
    watch,
    setValue,
  } = useForm<ProductFormModel>({ defaultValues: defaultFormValues });
  const { id: client_id } = useParams();

  const { updateProducts, editableProduct } = useContext(ClientProductContext)!;

  const type = watch("type");

  const onSubmit: SubmitHandler<ProductFormModel> = async (data) => {
    if (data.id) {
      await ProductController.update(data.id, {
        ...data,
      });
    } else {
      client_id &&
        (await ProductController.store({ ...data, client_id: client_id }));
    }
    updateProducts();
    modalRef().close();
    reset(defaultFormValues);
  };

  useEffect(() => {
    editableProduct ? reset(editableProduct) : reset(defaultFormValues);
  }, [editableProduct]);

  return (
    <dialog id="ProductModal" onClose={() => reset()} className="modal">
      <div className="modal-box max-w-[800px] w-full">
        <h3 className="font-bold text-lg">Adicionar Plano</h3>

        <form className="menu p-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-2 items-end  auto-rows-max">
            <Select
              label="Tipo de Produto"
              defaultOption="- -"
              options={getProductValueLabel()}
              {...register("type")}
              error={errors.type}
              required
            />

            <Select
              label="Status"
              defaultOption="- -"
              options={[
                { value: "true", label: "Ativo" },
                { value: "false", label: "Inativo" },
              ]}
              required
              {...register("status")}
              error={errors.status}
            />
          </div>
          <div className="grid grid-cols-3 gap-2 items-end  auto-rows-max">
            {type === `${ProductType.Consig}` && (
              <TextInput
                label="Domain id"
                error={errors.id}
                {...register("domain_id", { required: false })}
                disabled={editableProduct?.id ? true : false}
              />
            )}
            {(type === `${ProductType.NFEi9}` ||
              (editableProduct?.id && type === `${ProductType.Consig}`)) && (
              <TextInput
                label="URL"
                disabled={
                  editableProduct?.id ? type === `${ProductType.Consig}` : false
                }
                error={errors.url}
                {...register("url", { required: false })}
              />
            )}
            {type === `${ProductType.Consig}` && (
              <div className={`join ${!editableProduct?.id && " col-span-2"}`}>
                <TextInput
                  label="Data de Ativação GPS"
                  type="date"
                  className="join-item"
                  error={errors.consig?.gps_activation_date}
                  {...register("consig.gps_activation_date", {
                    required: false,
                  })}
                />
                <button
                  type="button"
                  onClick={() => setValue("consig.gps_activation_date", "")}
                  className="btn join-item mt-auto"
                >
                  Limpar
                </button>
              </div>
            )}
          </div>
          {editableProduct?.id && type === `${ProductType.Consig}` && (
            <div className="grid grid-cols-4 gap-2 items-end">
              <TextInput
                label="Qnt. Devices"
                error={errors.consig?.devices_quantity}
                {...register("consig.devices_quantity", { required: true })}
                type="number"
                required
              />
              <TextInput
                label="Qnt. Devices Varejo"
                error={errors.consig?.devices_quantity_wholesale}
                {...register("consig.devices_quantity_wholesale", {
                  required: true,
                })}
                type="number"
                required
              />
              <TextInput
                label="Qnt. Devices Adm"
                error={errors.consig?.devices_quantity_adm}
                {...register("consig.devices_quantity_adm", { required: true })}
                type="number"
                required
              />
              <TextInput
                label="Qnt. Veiculos"
                error={errors.consig?.vehicles_quantity}
                {...register("consig.vehicles_quantity", { required: true })}
                type="number"
                required
              />
            </div>
          )}
          <button className="btn btn-block mt-4">Salvar</button>
        </form>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button onClick={() => reset({})}>close</button>
      </form>
    </dialog>
  );
};
