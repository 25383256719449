import Dropdown from "components/Dropdown/dropdown";
import { ConfirmationModal, modalRef } from "components/Modal/confirmDelete";
import FilesController from "Controllers/FilesController";
import React from "react";
import { useParams } from "react-router-dom";
import { ClientFile } from "utils/interfaces";
interface RowData {
  row: ClientFile;
  onClick: (id: string) => void;
}

const ClientFileRows: React.FC<RowData> = ({ row, onClick }) => {
  const { id: client_id } = useParams();

  const confirmDelete = async () => {
    if (client_id) {
      await FilesController.deleteFiles(client_id, row.id);
      onClick(client_id);
    }
  };

  return (
    <React.Fragment key={row.id}>
      <tr className="hover cursor-pointer">
        <th>{row.id}</th>
        <td className="whitespace-nowrap ">{row.file_name}</td>
        <td className="whitespace-nowrap">{row.original_name}</td>
        <td className="flex justify-end pr-7">
          <Dropdown title="Ações">
            <ul>
              <li
                onClick={async () =>
                  client_id &&
                  (await FilesController.getDownloadLink(
                    client_id,
                    row.id,
                    row.file_name
                  ))
                }
              >
                <a>Baixar</a>
              </li>
              <li onClick={() => modalRef().showModal()}>
                <a>Remover</a>
              </li>
            </ul>
          </Dropdown>
          <ConfirmationModal onClick={confirmDelete} />
        </td>
      </tr>
    </React.Fragment>
  );
};

export default ClientFileRows;
