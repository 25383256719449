import React from "react";
import { ClientFile } from "utils/interfaces";
import ClientFileRows from "./Row/ClientFileRow";
import ClientFileHeader from "./Header/ClientFileHeader";

const ClientFileTable: React.FC<{
  rows: ClientFile[];
  update: (id: string) => void;
}> = ({ rows, update }) => {
  return (
    <table className="table w-full">
      <ClientFileHeader />
      <tbody>
        {rows.map((row, index) => (
          <ClientFileRows row={row} onClick={update} key={row.id} />
        ))}
      </tbody>
    </table>
  );
};

export default ClientFileTable;
