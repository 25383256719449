import React from "react";

interface DropdownProps {
  title: string;
}
const Dropdown: React.FC<React.PropsWithChildren<DropdownProps>> = ({
  title,
  children,
}) => {
  return (
    <div className="dropdown dropdown-left ">
      <div
        tabIndex={0}
        role="button"
        className="btn btn-ghost rounded-btn stroke-base-content fill-base-content"
      >
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 3.5H15M0 11.5H15M0 7.5H15" />
        </svg>
      </div>
      <ul
        tabIndex={0}
        className="menu dropdown-content bg-base-100 rounded-box z-[1] mt-4 w-52 p-2 shadow"
      >
        <li>
          <h2 className="menu-title">{title}</h2>
          {children}
        </li>
      </ul>
    </div>
  );
};

export default Dropdown;
