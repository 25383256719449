import React from "react";
import TableWithExpandableRows from "components/Table/PlanTable";
import { modalRef } from "components/Modal/registerPlan";
import { TableProvider } from "contexts/PlanTableProvider";

const PlanList: React.FC = () => {
  return (
    <div className="card shadow-xl w-full bg-base-100">
      <div className="card-body">
        <h2 className="card-title mb-1">
          <div className="mr-auto">Planos</div>
          <button
            onClick={() => {
              modalRef().showModal();
            }}
            className="btn btn-accent btn-accent-content"
          >
            Adicionar Plano
          </button>
        </h2>
        <TableProvider>
          <TableWithExpandableRows />
        </TableProvider>
      </div>
    </div>
  );
};

export default PlanList;
