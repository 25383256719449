const ClientProductHeader = () => {
  return (
    <thead>
      <tr>
        <th>#</th>
        <th>Produto</th>
        <th>Domain Id</th>
        <th>URL</th>
        <th>Status</th>
        <th>Acões</th>
      </tr>
    </thead>
  );
};

export default ClientProductHeader;
