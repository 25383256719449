import React, { useContext, useEffect, useState } from "react";
import LabelInsideInput from "./Input/LabelInsideInput";
import Select from "./Input/Select";
import InlineLabelButton from "./Input/InlineLabelButton";
import TextInput from "./Input/TextInput";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import {
  ClientFormModel,
  transformClientToFormModel,
} from "utils/DTOs/ClientForm";
import { normalizeDocument, normalizeZipCode } from "utils/formMasks";
import ClientController from "Controllers/ClientController";
import { Client, Location } from "utils/interfaces";
import { ClientDocumentType } from "utils/Enums/Client";
import UtilController from "Controllers/UtilControllers";
import { ClientStoreDrawerRef } from "components/Table/ClientTable";
import { formatDocument, formatZipCode } from "utils/DTOs/Documents";

interface InputData {
  selectedClient?: Client;
  updatePage?: () => void;
  goToLastPage?: () => void;
}

const ClientForm: React.FC<InputData> = ({
  selectedClient,
  updatePage,
  goToLastPage,
}) => {
  const defaultValues: ClientFormModel = {
    id: undefined,
    document: "",
    documentType: "1",
    name: "",
    fantasyName: "",
    cityId: "",
    stateId: "",
    street: "",
    district: "",
    number: "",
    zip: "",
    complement: "",
    nearby: "",
    contact: [{ contact: "", typeContact: "" }], // Mantém valor padrão
    due_day: "",
    info: "",
    domainId: "",
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ClientFormModel>({
    defaultValues: defaultValues,
    shouldUnregister: true,
  });

  const closeDrawer = () => {
    if (ClientStoreDrawerRef()) {
      ClientStoreDrawerRef().checked = false;
    }
  };

  const onSubmit: SubmitHandler<ClientFormModel> = async (data) => {
    if (data.id) {
      const updatedClient = await ClientController.put(data);
      updatePage && updatePage();
      updatedClient && reset(transformClientToFormModel(updatedClient));
    } else {
      await ClientController.store(data);
      goToLastPage && goToLastPage();
      reset(defaultValues);
    }
    closeDrawer();
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "contact",
    rules: { minLength: 1, required: true },
  });

  const [citiesList, setCitiesList] = useState<Location[]>([]);
  const [statesList, setStatesList] = useState<Location[]>([]);
  const stateId = watch("stateId");
  const cityId = watch("cityId");
  const document_ = watch("document");
  const id = watch("id");
  const documentType = watch("documentType");

  useEffect(() => {
    if (stateId) {
      const updateCities = async () =>
        setCitiesList(await ClientController.getCities(stateId));
      updateCities();
    }
  }, [stateId]);

  useEffect(() => {
    const updateStates = async () =>
      setStatesList(await ClientController.getStates());
    updateStates();
    ClientStoreDrawerRef()?.addEventListener("change", function (event) {
      const input = event.target as HTMLInputElement;
      if (!input.checked) {
        reset(defaultValues);
      }
    });
  }, []);

  useEffect(() => {
    setValue("cityId", cityId);
  }, [citiesList, setValue]);

  useEffect(() => {
    if (selectedClient) {
      const transformedData = transformClientToFormModel(selectedClient);
      reset(transformedData);
    } else {
      reset(defaultValues);
    }
  }, [selectedClient, reset]);

  const findByCNPJ = async () => {
    if (
      documentType === ClientDocumentType.JURIDICA &&
      document_?.length === 18
    ) {
      const clientFormData = await UtilController.fillInClientFormByCNPJ(
        document_
      );

      clientFormData &&
        reset({
          ...clientFormData,
          zip: clientFormData.zip && formatZipCode(clientFormData.zip),
          document:
            clientFormData.document && formatDocument(clientFormData.document),
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="menu p-4">
      <div className="grid grid-cols-3 mb-3 gap-2 items-end  auto-rows-max">
        <input type="hidden" {...register("id")} />

        <Select
          label="Selecione o tipo de cliente"
          defaultOption="Tipo de Pessoa"
          defaultValue="1"
          options={[
            { value: 0, label: "Fisica" },
            { value: 1, label: "Juridica" },
          ]}
          {...register("documentType", { required: true })}
          error={errors.documentType}
        />

        <div className="join col-span-2">
          <input
            type="text"
            className="input w-full join-item input-bordered flex items-center"
            placeholder="Procurar CNPJ"
            onInput={normalizeDocument}
            disabled={!documentType}
            {...register("document")}
          />
          <button
            type="button"
            onClick={findByCNPJ}
            className="btn join-item btn-accent"
            disabled={documentType !== ClientDocumentType.JURIDICA}
          >
            Pesquisar
          </button>
        </div>
      </div>
      <div className="grid grid-cols-5 gap-2 items-end  auto-rows-max">
        <TextInput
          label="Nome"
          className="col-span-4"
          error={errors.name}
          {...register("name", { required: true })}
        />

        <Select
          label="Data de Vencimento"
          defaultOption="- -"
          options={[
            { value: 5, label: "05" },
            { value: 10, label: "10" },
            { value: 20, label: "20" },
            { value: 25, label: "25" },
          ]}
          {...register("due_day")}
          error={errors.documentType}
        />

        <TextInput
          label="Fantasia"
          className="col-span-5"
          error={errors.fantasyName}
          {...register("fantasyName", { required: true })}
        />
      </div>
      <div className="grid grid-cols-2 gap-2 items-end  auto-rows-max">
        <div className="text-base select-none font-medium col-span-2 divider divider-start">
          Informações de Endereço
        </div>
        <Select
          label="Selecione um estado"
          defaultOption="Escolha uma estado"
          options={statesList.map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
          error={errors.stateId}
          {...register("stateId")}
        />
        <Select
          label="Selecione uma cidade"
          defaultOption="Escolha uma cidade"
          options={citiesList.map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
          error={errors.cityId}
          {...register("cityId")}
        />
        <TextInput label="Rua" error={errors.street} {...register("street")} />
        <TextInput
          label="Bairro"
          error={errors.district}
          {...register("district")}
        />
        <TextInput
          label="Numero"
          error={errors.number}
          {...register("number")}
        />
        <TextInput
          label="Cep"
          error={errors.zip}
          onInput={normalizeZipCode}
          {...register("zip")}
        />
        <TextInput
          label="Proximo"
          error={errors.nearby}
          {...register("nearby")}
        />
        <TextInput
          label="Complemento"
          error={errors.complement}
          {...register("complement", { maxLength: 20 })}
        />
      </div>
      <div className="grid grid-cols-3 gap-2 items-end my-3">
        <div className="text-base font-medium select-none col-span-3 divider divider-start">
          Informações de Contato
        </div>
        {fields.map((field, index) => (
          <>
            <TextInput
              label="Contato"
              key={field.id}
              {...register(`contact.${index}.contact`, { required: true })}
              error={errors.contact?.[index]?.contact}
            />
            <Select
              label="Tipo"
              defaultOption="Selecione um tipo"
              options={[
                { value: 0, label: "Email" },
                { value: 1, label: "Mobile" },
                { value: 2, label: "Telefone" },
              ]}
              {...register(`contact.${index}.typeContact`, {
                required: true,
              })}
              error={errors.contact?.[index]?.typeContact}
            />
            <div className="flex flex-row w-full items-end">
              <input
                type="checkbox"
                aria-label="Selecionar como Padrão"
                className="btn flex-grow"
              />
              <InlineLabelButton
                onClick={() => remove(index)}
                label="Remover"
              />
            </div>
          </>
        ))}
      </div>
      <button
        type="button"
        onClick={() => append({ typeContact: "", contact: "" })}
        className="btn btn-block btn-sm mb-3"
      >
        Adicionar outro Contato
      </button>

      <button className="btn my-3 mt-auto btn-accent text-accent-content">
        {id ? "Salvar" : "Cadastrar"}
      </button>
    </form>
  );
};

export default ClientForm;
