import React, { createContext, useEffect, useState } from "react";
import { PaginatedResponse } from "utils/interfaces";
import { Invoice } from "utils/interfaces/Invoices";
import InvoiceController from "Controllers/InvoiceController";
import { useParams } from "react-router-dom";

interface InvoiceTableContextProps {
  invoicesTable: PaginatedResponse<Invoice>;
  setInvoicesTable: (plans: PaginatedResponse<Invoice>) => void;
  editableInvoice: Invoice | undefined;
  setEditableInvoice: (plan: Invoice | undefined) => void;
  goToPage: (page: number) => Promise<void>;
  updateCurrentPage: () => Promise<void>;
  goToLastPage: () => Promise<void>;
}

export const InvoiceTableContext = createContext<
  InvoiceTableContextProps | undefined
>(undefined);

export const InvoiceTableProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { id: client_id } = useParams();

  const [editableInvoice, setEditableInvoice] = useState<Invoice>();
  const [invoicesTable, setInvoicesTable] = useState<
    PaginatedResponse<Invoice>
  >(InvoiceController.defaultPaginatedResponse);

  const goToPage = async (page: number) => {
    if (client_id) {
      const plans = await InvoiceController.list(page, client_id);
      setInvoicesTable(plans);
    }
  };

  const goToLastPage = async () => {
    if (client_id) {
      const plans = await InvoiceController.list(
        invoicesTable.last_page,
        client_id
      );
      setInvoicesTable(plans);
    }
  };

  const updateCurrentPage = async () => {
    if (client_id) {
      const plans = await InvoiceController.list(
        invoicesTable.current_page,
        client_id
      );
      setInvoicesTable(plans);
    }
  };

  useEffect(() => {
    goToPage(1);
  }, []);

  return (
    <InvoiceTableContext.Provider
      value={{
        editableInvoice,
        invoicesTable,
        goToLastPage,
        goToPage,
        updateCurrentPage,
        setEditableInvoice,
        setInvoicesTable,
      }}
    >
      {children}
    </InvoiceTableContext.Provider>
  );
};
