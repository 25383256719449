import React from "react";
import { ClientPlanInfo } from "utils/interfaces";
import ClientPlanHeader from "./Header/ClientPlanHeader";
import ClientPlanRows from "./Row/ClientPlanRow";

const ClientPlanTable: React.FC<{
  rows: ClientPlanInfo[];
  updatePlans: (id: string | number) => void;
}> = ({ rows, updatePlans }) => {
  return (
    <table className="table w-full">
      <ClientPlanHeader />
      <tbody>
        {rows.map((row, index) => (
          <ClientPlanRows row={row} updatePlans={updatePlans} />
        ))}
      </tbody>
    </table>
  );
};

export default ClientPlanTable;
