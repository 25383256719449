import Dropdown from "components/Dropdown/dropdown";
import { modalRef } from "components/Modal/registerPlan";
import { TableContext } from "contexts/PlanTableProvider";
import PlanController from "Controllers/PlanController";
import React, { useContext } from "react";
import { formatNumberToMonetaryValue } from "utils/DTOs/MonetaryValue";
import { Plan } from "utils/interfaces";
interface RowData {
  row: Plan;
}

const PlanRows: React.FC<RowData> = ({ row }) => {
  const { setEditablePlan, updateCurrentPage } = useContext(TableContext)!;

  const editPlan = () => {
    setEditablePlan(row);
    modalRef().showModal();
  };

  const deletePlan = () => {
    PlanController.delete(row.id);
    updateCurrentPage();
  };
  const enablePlan = () => {
    PlanController.enable(row.id);
    updateCurrentPage();
  };
  return (
    <React.Fragment key={row.id}>
      <tr className="hover cursor-pointer">
        <th>{row.id}</th>
        <td className="whitespace-nowrap">{row.name}</td>
        <td>
          {row.deleted ? (
            <span className="rounded-badge border border-error/50 bg-error/5 px-3 py-1 text-xs font-medium text-error">
              Deletado
            </span>
          ) : (
            <span className="rounded-badge border border-success/50 bg-success/5 px-3 py-1 text-xs font-medium text-success">
              Ativo
            </span>
          )}
        </td>
        <td>{formatNumberToMonetaryValue(row.value)}</td>
        <td className="py-0">
          <Dropdown title="Ações">
            <ul>
              <li onClick={editPlan}>
                <a>Editar</a>
              </li>
              {row.deleted ? (
                <li onClick={enablePlan}>
                  <a>Ativar</a>
                </li>
              ) : (
                <li onClick={deletePlan}>
                  <a>Desativar</a>
                </li>
              )}
            </ul>
          </Dropdown>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default PlanRows;
