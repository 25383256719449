import React, { useContext, useEffect, useState } from "react";
import PlanRows from "./Row/PlanRow";
import PlanHeader from "./Header/PlanHeader";
import PageController from "./PageController";
import { PlanModal } from "components/Modal/registerPlan";
import { TableContext } from "contexts/PlanTableProvider";

interface TableProps {}

const TableWithExpandableRows: React.FC<TableProps> = ({}) => {
  const { tablePlans, goToPage } = useContext(TableContext)!;

  return (
    <div>
      <div className="min-h-[510px]">
        <table className="table w-full">
          <PlanHeader />
          <tbody>
            {tablePlans.data.map((row, index) => (
              <PlanRows row={row} key={row.id} />
            ))}
          </tbody>
        </table>
      </div>
      <PageController {...tablePlans} changePage={goToPage} />
      <PlanModal />
    </div>
  );
};

export default TableWithExpandableRows;
