export enum ProductType {
  Consig = 0,
  NetCom = 1,
  NFEi9 = 2,
}

export function getProductTypeDescription(type: ProductType): string {
  switch (type) {
    case ProductType.Consig:
      return "Consig";
    case ProductType.NetCom:
      return "NetCom";
    case ProductType.NFEi9:
      return "NFEi9";
    default:
      return "Unknown";
  }
}

export function getProductValueLabel(): Array<{
  value: number;
  label: string;
}> {
  return [
    { value: ProductType.Consig, label: "Consig" },
    { value: ProductType.NetCom, label: "NetCom" },
    { value: ProductType.NFEi9, label: "NFE-i9" },
  ];
}
