import Dropdown from "components/Dropdown/dropdown";
import ClientPlanController from "Controllers/ClientPlanController";
import React from "react";
import { useParams } from "react-router-dom";
import { ClientPlanInfo } from "utils/interfaces";
interface RowData {
  row: ClientPlanInfo;
  updatePlans: (id: string | number) => void;
}

const ClientPlanRows: React.FC<RowData> = ({ row, updatePlans }) => {
  const { id: client_id } = useParams();

  function formatDate(apiDate: string) {
    const date = new Date(apiDate);
    return date.toLocaleString("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  }

  const unlinkPlan = async () => {
    if (client_id) {
      await ClientPlanController.unlinkPlan(row.id, client_id);
      updatePlans(client_id);
    }
  };
  const relinkPlan = async () => {
    if (client_id) {
      await ClientPlanController.relinkPlan(row.id, client_id);
      updatePlans(client_id);
    }
  };

  return (
    <React.Fragment key={row.id}>
      <tr className="hover cursor-pointer">
        <th>{row.id}</th>
        <td className="whitespace-nowrap">{row.name}</td>
        <td>{row.cancellation_date ? "Cancelado" : "Ativo"}</td>
        <td>{formatDate(row.signature_date)}</td>
        <td>{row.value}</td>
        <td>
          <Dropdown title="Ações">
            <ul>
              <li>
                {row.cancellation_date ? (
                  <div onClick={relinkPlan}>Reativar</div>
                ) : (
                  <div onClick={unlinkPlan}>Deletar</div>
                )}
              </li>
            </ul>
          </Dropdown>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default ClientPlanRows;
