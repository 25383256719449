export enum ClientDocumentType {
  FISICA = "0",
  JURIDICA = "1",
}

export enum ClientContactType {
  Email = "0",
  Mobile = "1",
  Telephone = "2",
}
