export const modalRef = () =>
  document.getElementById("my_modal_2") as HTMLDialogElement;

interface Props {
  onClick: () => any;
}

export const ConfirmationModal: React.FC<Props> = ({ onClick }) => {
  const confirm = () => {
    onClick();
    modalRef().close();
  };

  return (
    <dialog id="my_modal_2" className="modal">
      <div className="modal-box">
        <h3 className="font-bold text-lg">Confirmar exclusão</h3>
        <p className="py-4">Isso removerá permanentemente os dados</p>
        <div className="flex justify-end flex-row">
          <form method="dialog">
            <button className="btn mr-2">Cancelar</button>
          </form>
          <button onClick={confirm} className="btn btn-warning">
            Remover
          </button>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};
