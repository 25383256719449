import React, { useContext, useState } from "react";
import ClientHeader from "./Header/ClentHeader";
import ClientRows from "./Row/ClientRow";
import ClientForm from "components/Forms/ClientForm";
import PageController from "./PageController";
import { ClientListContext } from "contexts/ClientListProvider";

export const IdForm = "clientForm";

export const ClientStoreDrawerRef = () =>
  document.getElementById(IdForm) as HTMLInputElement;

const TableWithExpandableRows: React.FC = () => {
  const {
    getClient,
    tableClient,
    getClientsPerPage,
    removeClient,
    selectedClient,
    goToLastPage,
    updatePage,
  } = useContext(ClientListContext)!;
  const [expandedIndex, setexpandedIndex] = useState<number>();

  const toggleRow = (id?: number) => {
    setexpandedIndex(id);
  };

  const editClient = (id: number) => {
    return () => getClient(id);
  };
  return (
    <div className="min-h-[510px]">
      <table className="table w-full">
        <ClientHeader />
        <tbody>
          {tableClient.data.map((row) => (
            <ClientRows
              row={row}
              onClick={toggleRow}
              key={row.id}
              active={expandedIndex === row.id}
              editRow={editClient(row.id)}
            />
          ))}
        </tbody>
      </table>
      <PageController {...tableClient} changePage={getClientsPerPage} />

      <div className="drawer drawer-end">
        <input id={IdForm} type="checkbox" className="drawer-toggle" />
        <div className="drawer-side min-h-screen">
          <label
            htmlFor={IdForm}
            aria-label="close sidebar"
            onClick={() => {
              removeClient();
            }}
            className="drawer-overlay"
          ></label>
          <div className="bg-base-200 text-base-content min-h-full max-w-[800px] w-full">
            <ClientForm
              {...{
                selectedClient,
                goToLastPage,
                updatePage,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableWithExpandableRows;
