import { AuthContext } from "contexts/AuthProvider";
import React, { useContext } from "react";

const UserInformation = () => {
  const { userName, logout } = useContext(AuthContext)!;
  return (
    <div className="dropdown dropdown-bottom dropdown-end ml-2">
      {/* Botão do dropdown */}
      <label
        tabIndex={0}
        className="btn btn-ghost rounded-btn px-1.5 hover:bg-base-content/20"
      >
        <div className="flex items-center gap-2">
          {/* Avatar */}
          <div aria-label="Avatar photo" className="avatar">
            <div className="bg-neutral text-neutral-content w-[40px] rounded-full">
              <span className="text-3xl">{userName && userName[0]}</span>
            </div>
          </div>
          {/* Nome e ação */}
          <div className="flex flex-col items-start">
            <p className="text-sm/none">{userName}</p>
            <p className="mt-1 text-xs/none text-primary">Ações</p>
          </div>
        </div>
      </label>

      {/* Conteúdo do dropdown */}
      <ul
        tabIndex={0}
        className="dropdown-content menu p-2 shadow bg-base-100 rounded-box mt-4 w-52 z-50"
        role="menu"
      >
        {/* Separador */}
        <hr className="-mx-2 my-1 border-base-content/10" />

        {/* Opção 3 (Logout) */}
        <li>
          <div className="flex items-center gap-2 text-error" onClick={logout}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              fontSize="16"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4m7 14l5-5l-5-5m5 5H9"
              ></path>
            </svg>
            Logout
          </div>
        </li>
      </ul>
    </div>
  );
};

export default UserInformation;
