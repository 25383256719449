import LabelInsideInput from "components/Forms/Input/LabelInsideInput";
import { PlanFormProps } from "components/Forms/interface/PlanForm";
import { TableContext } from "contexts/PlanTableProvider";
import PlanController from "Controllers/PlanController";
import { useContext, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  formatNumberToMonetaryValue,
  parseMonetaryValueToNumber,
} from "utils/DTOs/MonetaryValue";
import { normalizeMonetaryValue } from "utils/formMasks";

export const modalRef = () =>
  document.getElementById("PlanModal") as HTMLDialogElement;

interface Props {}

export const PlanModal: React.FC<Props> = ({}) => {
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<PlanFormProps>({});

  const { updateCurrentPage, editablePlan, goToLastPage } =
    useContext(TableContext)!;

  const onSubmit: SubmitHandler<PlanFormProps> = async (data) => {
    if (data.id) {
      await PlanController.put({
        ...data,
        value: parseMonetaryValueToNumber(`${data.value}`),
      });
      updateCurrentPage();
    } else {
      await PlanController.store({
        ...data,
        value: parseMonetaryValueToNumber(`${data.value}`),
      });
      goToLastPage();
    }
    modalRef().close();
    reset();
  };

  useEffect(() => {
    editablePlan &&
      reset({
        ...editablePlan,
        value: formatNumberToMonetaryValue(editablePlan.value),
      });
    console.log(editablePlan);
  }, [editablePlan]);

  return (
    <dialog id="PlanModal" onClose={() => reset()} className="modal">
      <div className="modal-box max-w-[800px] w-full">
        <h3 className="font-bold text-lg">Adicionar Plano</h3>
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" {...register("id")} />

          <div className="grid grid-cols-2 gap-2 items-end  auto-rows-max">
            <LabelInsideInput
              label="Nome"
              error={errors.name}
              {...register("name", { required: true, max: 45 })}
            />
            <LabelInsideInput
              label="Valor"
              error={errors.value}
              {...register("value", { required: true })}
              onInput={normalizeMonetaryValue}
            />
          </div>

          <div className="flex justify-end flex-row my-2">
            <button
              className="btn mr-2"
              onClick={() => {
                modalRef().close();
                reset({});
              }}
            >
              Cancelar
            </button>
            <button type="submit" className="btn btn-warning">
              Adicionar
            </button>
          </div>
        </form>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button onClick={() => reset({})}>close</button>
      </form>
    </dialog>
  );
};
