import { toast } from "react-toastify";
import CrmAPI from "services/axios/CrmApi";
import { AxiosError } from "axios";
import {
  ClientFormModel,
  transformClientToFormModel,
} from "utils/DTOs/ClientForm";
import { ApiError } from "utils/interfaces/responses/error";
import ClientController from "./ClientController";
export default class UtilController {
  static async fillInClientFormByCNPJ(
    cnpj: string
  ): Promise<ClientFormModel | null> {
    return toast
      .promise(CrmAPI.get(`/clients/cnpj?cnpj=${encodeURIComponent(cnpj)}`), {
        pending: "Pesquisando CNPJ",
        error: {
          render({ data }: { data: AxiosError<ApiError> }) {
            return `${data.response?.data.message}`;
          },
        },
      })
      .then(async (response) => {
        if (response.data) {
          await ClientController.getStates(true);
          await ClientController.getCities(
            response.data.address.state_id,
            true
          );
          return transformClientToFormModel(response.data);
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  }
}
