import { Link, useLocation, useParams } from "react-router-dom";

interface Props {
  id?: string;
}

const ClienteTabs: React.FC<Props> = ({}) => {
  const tabs: { label: string; path: string }[] = [
    {
      label: "Cliente",
      path: "/clients",
    },
    {
      label: "Planos",
      path: "/clients/plans",
    },
    {
      label: "Produto",
      path: "/clients/product",
    },
    {
      label: "Fatura",
      path: "/clients/invoices",
    },
    {
      label: "Arquivos",
      path: "/clients/files",
    },
  ];
  const { id } = useParams();

  const location = useLocation();

  return (
    <div role="tablist" className="tabs tabs-lifted">
      {tabs.map(({ label, path }) => (
        <Link
          to={`${path}/${id}`}
          role="tab"
          className={`tab ${
            location.pathname.replace(/\/\d+$/, "") === path && "tab-active"
          }`}
        >
          {label}
        </Link>
      ))}
    </div>
  );
};

export default ClienteTabs;
