import React, { forwardRef } from "react";
import { FieldError } from "react-hook-form";

interface InputData extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: FieldError;
  className?: string;
  placeholder?: string;
}

const LabelInsideInput = forwardRef<HTMLInputElement, InputData>(
  ({ label, error, className, ...rest }, ref) => {
    return (
      <label
        className={`text-nowrap input input-bordered flex items-center gap-2 ${className} ${
          error && "input-error"
        }`}
      >
        {label}:
        <input type="text" className="grow" ref={ref} {...rest} />
      </label>
    );
  }
);

export default LabelInsideInput;
