import React, { useContext, useEffect, useState } from "react";
import { ClientProductContext } from "contexts/ClientProductProvider";
import ClientProductHeader from "./Header/ClientProductHeader";
import ClientProductRow from "./Row/ClientProductRow";
import { modalRef, ProductModal } from "components/Modal/clientProduct";

interface TableProps {}

const ClientProductTable: React.FC<TableProps> = ({}) => {
  const { tableProduct, setEditableProduct } =
    useContext(ClientProductContext)!;

  return (
    <div>
      <h2 className="card-title mb-1">
        <div className="mr-auto">Planos</div>
        <button
          onClick={() => {
            setEditableProduct(undefined);
            modalRef().showModal();
          }}
          className="btn btn-accent btn-accent-content"
        >
          Adicionar Produto
        </button>
      </h2>
      <div className="min-h-[510px]">
        <table className="table w-full">
          <ClientProductHeader />
          <tbody>
            {tableProduct.map((row, index) => (
              <ClientProductRow row={row} key={row.id} />
            ))}
          </tbody>
        </table>
      </div>
      <ProductModal />
    </div>
  );
};

export default ClientProductTable;
