import React, { useEffect, useState } from "react";
import ClienteTabs from "components/Tabs/ClienteTabs";
import { useParams } from "react-router-dom";
import ClientForm from "components/Forms/ClientForm";
import ClientController from "Controllers/ClientController";
import { Client } from "utils/interfaces";

const ClientInfo: React.FC = () => {
  const [client, setClient] = useState<Client>();
  const { id: client_id } = useParams();

  useEffect(() => {
    const getClient = async () =>
      client_id && setClient(await ClientController.getClientById(client_id));
    getClient();
  }, [client_id]);

  return (
    <div className="card shadow-xl w-full bg-base-100">
      <div className="card-body">
        <ClienteTabs id={client_id} />
        <ClientForm selectedClient={client} />
      </div>
    </div>
  );
};

export default ClientInfo;
