import React, { useContext } from "react";
import { Link } from "react-router-dom";
import SubText from "./SideBarItens/SubText";
import MenuButton from "./SideBarItens/MenuButton";
import MenuGroup from "./SideBarItens/MenuGroup";
import HoraizontalLine from "./SideBarItens/HorizontalLine";
import { AuthContext } from "contexts/AuthProvider";

type SidebarProps = {};

const Sidebar: React.FC<SidebarProps> = () => {
  const { logout } = useContext(AuthContext);

  return (
    <aside className=" box-border w-[240px] left-menu flex border-r border-base-300 flex-col h-full">
      <nav
        aria-label="Main"
        className="select-none flex-col flex h-full px-2 pb-4 overflow-y-hidden hover:overflow-y-auto"
      >
        <Link
          to={"/"}
          className="text-center w-full flex h-[64px] items-center justify-center text-2xl font-bold tracking-wider uppercase text-base-content"
        >
          CRM Netinove
        </Link>
        <div className="h-6"></div>
        <SubText label="Main" />
        <MenuButton
          href="/"
          label="Home"
          icon={
            <svg
              className="h-5 w-5"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7.5 0.5L7.8254 0.120372C7.63815 -0.0401239 7.36185 -0.0401239 7.1746 0.120372L7.5 0.5ZM0.5 6.5L0.174604 6.12037L0 6.27003V6.5H0.5ZM5.5 14.5V15C5.77614 15 6 14.7761 6 14.5H5.5ZM9.5 14.5H9C9 14.7761 9.22386 15 9.5 15V14.5ZM14.5 6.5H15V6.27003L14.8254 6.12037L14.5 6.5ZM1.5 15H5.5V14H1.5V15ZM14.8254 6.12037L7.8254 0.120372L7.1746 0.879628L14.1746 6.87963L14.8254 6.12037ZM7.1746 0.120372L0.174604 6.12037L0.825396 6.87963L7.8254 0.879628L7.1746 0.120372ZM6 14.5V11.5H5V14.5H6ZM9 11.5V14.5H10V11.5H9ZM9.5 15H13.5V14H9.5V15ZM15 13.5V6.5H14V13.5H15ZM0 6.5V13.5H1V6.5H0ZM7.5 10C8.32843 10 9 10.6716 9 11.5H10C10 10.1193 8.88071 9 7.5 9V10ZM7.5 9C6.11929 9 5 10.1193 5 11.5H6C6 10.6716 6.67157 10 7.5 10V9ZM13.5 15C14.3284 15 15 14.3284 15 13.5H14C14 13.7761 13.7761 14 13.5 14V15ZM1.5 14C1.22386 14 1 13.7761 1 13.5H0C0 14.3284 0.671573 15 1.5 15V14Z" />
            </svg>
          }
        />
        <MenuGroup
          label="Cadastros"
          icon={
            <svg
              className="h-5 w-5"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 0.5H1.5C0.947715 0.5 0.5 0.947715 0.5 1.5V4M6 0.5H9M11 0.5H13.5C14.0523 0.5 14.5 0.947715 14.5 1.5V4M0.5 6V9M14.5 6V9M0.5 11V13.5C0.5 14.0523 0.947715 14.5 1.5 14.5H4M14.5 11V13.5C14.5 14.0523 14.0523 14.5 13.5 14.5H11M7.5 4V11M4 7.5H11M6 14.5H9" />
            </svg>
          }
          submenu={[
            { label: "Clientes", disabled: false, href: "/clients/index" },
            { label: "Planos", disabled: false, href: "/plans/index" },
          ]}
        />
        <HoraizontalLine />
        <SubText label="Finances" />
        <MenuButton
          href="/"
          label="Analitcs"
          icon={
            <svg
              className="h-5 w-5"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0.5 14.5H0V15H0.5V14.5ZM6.5 5.5L6.9 5.2C6.80264 5.07018 6.64833 4.99569 6.48613 5.00019C6.32392 5.00469 6.17399 5.08763 6.08397 5.22265L6.5 5.5ZM9.5 9.5L9.1 9.8C9.19566 9.92754 9.3464 10.0018 9.50581 9.99997C9.66523 9.99811 9.8142 9.92035 9.90687 9.79062L9.5 9.5ZM0 0V14.5H1V0H0ZM0.5 15H15V14H0.5V15ZM2.91603 11.7774L6.91603 5.77735L6.08397 5.22265L2.08397 11.2226L2.91603 11.7774ZM6.1 5.8L9.1 9.8L9.9 9.2L6.9 5.2L6.1 5.8ZM9.90687 9.79062L14.9069 2.79062L14.0931 2.20938L9.09313 9.20938L9.90687 9.79062Z" />
            </svg>
          }
        />
        <MenuGroup
          label="Pagamentos"
          icon={
            <svg
              className="h-5 w-5"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 3.5H15M7 7.5H15M3 11.5H15M0.5 9.5L2.5 7.5L0.5 5.5"
                strokeLinejoin="round"
              />
            </svg>
          }
          submenu={[
            { label: "Livro", disabled: true, href: "/" },
            { label: "Suspensões", disabled: true, href: "/" },
          ]}
        />
        <div className="mt-auto"></div>
        <HoraizontalLine />
        <MenuButton
          href="/"
          onClick={logout}
          label="Logout"
          icon={
            <svg
              className="h-5 w-5"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M13.5 7.5L10.5 10.75M13.5 7.5L10.5 4.5M13.5 7.5L4 7.5M8 13.5H1.5L1.5 1.5L8 1.5" />
            </svg>
          }
        />
      </nav>
    </aside>
  );
};

export default Sidebar;
