export const normalizeCnpjNumber = (value: String | undefined) => {
  if (!value) return "";

  return value
    .replace(/[\D]/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};

export const normalizeCepNumber = (value: String | undefined) => {
  if (!value) return "";
  return value
    .replace(/\D/g, "")
    .replace(/^(\d{5})(\d{3})+?$/, "$1-$2")
    .replace(/(-\d{3})(\d+?)/, "$1");
};

export const normalizeDocument = (
  input: React.ChangeEvent<HTMLInputElement>
): void => {
  const cleanedValue = input.target.value.replace(/\D/g, "");

  if (cleanedValue.length <= 11) {
    input.target.value = cleanedValue
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1-$2");
  } else {
    input.target.value = cleanedValue
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  }
};

export const normalizeZipCode = (
  input: React.ChangeEvent<HTMLInputElement>
) => {
  input.target.value = input.target.value
    .replace(/\D/g, "")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{3})\d+?$/, "$1");
};

export const normalizePhoneNumber = (
  input: React.ChangeEvent<HTMLInputElement>
): void => {
  const cleaned = input.target.value.replace(/\D/g, "");

  if (cleaned.length <= 10) {
    input.target.value = cleaned
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{4})(\d)/, "$1-$2");
  }

  if (cleaned.length === 11) {
    input.target.value = cleaned
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2");
  }
};

export function normalizeMonetaryValue(
  input: React.ChangeEvent<HTMLInputElement>
): void {
  let value = input.target.value.replace(/[^\d]/g, "");

  if (value) {
    value = (parseInt(value) / 100).toFixed(2).replace(".", ",");

    value = value.replace(/(\d)(?=(\d{3})+(\,|$))/g, "$1.");

    input.target.value = `R$ ${value}`;
  }
}
