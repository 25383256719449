import { AxiosError } from "axios";
import { toast } from "react-toastify";
import apiClient from "services/axios/CrmApi";
import { ClientFormModel } from "utils/DTOs/ClientForm";
import { Client, PaginatedResponse } from "utils/interfaces";
import { ApiError } from "utils/interfaces/responses/error";
import { InvoiceFormProps } from "utils/interfaces/forms/InvoiceForm";
import { Invoice } from "utils/interfaces/Invoices";

export default class InvoiceController {
  static defaultPaginatedResponse: PaginatedResponse<Invoice> = {
    current_page: 1,
    data: [],
    from: 0,
    last_page: 1,
    per_page: 0,
    to: 0,
    total: 0,
  };

  static async list(
    page: number | string,
    client_id: number | string
  ): Promise<PaginatedResponse<Invoice>> {
    return toast
      .promise(apiClient.get(`/invoices/${client_id}?page=${page}`), {
        pending: "Atualizando lista de Faturas",
        error: {
          render({ data }: { data: AxiosError<ApiError> }) {
            return `${data.response?.data.message}`;
          },
        },
      })
      .then(({ data }) => {
        if (data.data) {
          return data.data as PaginatedResponse<Invoice>;
        } else {
          return this.defaultPaginatedResponse;
        }
      })
      .catch((error) => {
        console.log(error);
        return this.defaultPaginatedResponse;
      });
  }

  static async getClientById(id: number | string): Promise<Client | undefined> {
    return toast
      .promise(apiClient.get(`/clients/${id}`), {
        pending: "Obtendo Informações de cliente...",

        error: {
          render({ data }: { data: AxiosError<ApiError> }) {
            return `${data.response?.data.message}`;
          },
        },
      })
      .then(({ data }) => {
        if (data.data) {
          return data.data as Client;
        } else {
          return undefined;
        }
      })
      .catch((error) => {
        console.log(error);
        return undefined;
      });
  }

  static async store(invoice: InvoiceFormProps, client_id: string | number) {
    return toast
      .promise(apiClient.post(`/invoices/${client_id}/`, invoice), {
        pending: "Cadastrando Fatura",
        success: "Cadastro Concluido",

        error: {
          render({ data }: { data: ApiError }) {
            return `${data.message}`;
          },
        },
      })
      .then((response) => {
        if (response.data) {
          return response.data as PaginatedResponse<Client>;
        } else {
          return this.defaultPaginatedResponse;
        }
      })
      .catch((error) => {
        console.log(error);
        return this.defaultPaginatedResponse;
      });
  }

  static async put(client: ClientFormModel) {
    return toast
      .promise(
        apiClient.put(`/clients/${client.id}`, {
          ...client,
          fantasy_name: client.fantasyName,
          document_type: client.documentType,
          address: {
            street: client.street,
            number: client.number,
            complement: client.complement,
            district: client.district,
            nearby: client.nearby,
            zip: client.zip,
            city_id: client.cityId,
          },
          contacts: client.contact.map(({ contact, typeContact }) => ({
            contact,
            type: typeContact,
          })),
        }),
        {
          pending: "Atualizando cliente",
          success: "Atualização concluida",

          error: {
            render({ data }: { data: ApiError }) {
              return `${data.message}`;
            },
          },
        }
      )
      .then((response) => {
        if (response.data) {
          return response.data as Client;
        } else {
          return undefined;
        }
      })
      .catch((error) => {
        console.log(error);
        return undefined;
      });
  }
}
