import React, { useEffect, useState } from "react";
import { ClientFile } from "utils/interfaces";
import ClienteTabs from "components/Tabs/ClienteTabs";
import { useParams } from "react-router-dom";
import LabelInsideInput from "components/Forms/Input/LabelInsideInput";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import ClientFileTable from "components/Table/ClientFileTable";
import FilesController from "Controllers/FilesController";

interface InputsData {
  files: {
    file_name: string;
    file: FileList | null;
  }[];
}

const ClientFiles: React.FC = () => {
  const { id: client_id } = useParams();
  const [tableFiles, setTableFiles] = useState<ClientFile[]>([]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<InputsData>({
    defaultValues: {
      files: [{ file_name: "", file: null }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "files",
    rules: { minLength: 1, required: true },
  });

  const onSubmit: SubmitHandler<InputsData> = async (data) => {
    const formData = new FormData();

    data.files.forEach((item) => {
      if (item.file) {
        const fileNameWithExtension = `${item.file_name}.${item.file[0].name
          .split(".")
          .pop()}`;
        formData.append(`files[]`, item.file[0]);
        formData.append(`file_names[]`, fileNameWithExtension);
        formData.append(`original_file_names[]`, item.file[0].name);
      }
    });

    if (client_id) {
      await FilesController.uploadFiles(client_id, formData);
      reset();
      updateFiles(client_id);
    }
  };

  const updateFiles = async (id: string) => {
    const files_list = await FilesController.getFiles(id);
    setTableFiles(files_list);
  };

  useEffect(() => {
    if (client_id) {
      updateFiles(client_id);
    }
  }, [client_id]);

  return (
    <div className="card shadow-xl w-full bg-base-100">
      <div className="card-body">
        <ClienteTabs id={client_id} />
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          {fields.map((field, index) => (
            <div
              className="grid grid-cols-6 gap-2 items-end my-1"
              key={field.id}
            >
              <LabelInsideInput
                className="col-span-2"
                label="Nome do Arquivo"
                error={errors.files?.[index]?.file_name}
                {...register(`files.${index}.file_name`, { required: true })}
              />
              <input
                type="file"
                className={`col-span-3 file-input file-input-bordered w-full ${
                  errors.files?.[index]?.file && "input-error"
                }`}
                {...register(`files.${index}.file`, { required: true })}
              />

              <button
                type="button"
                className="btn"
                onClick={() => remove(index)}
              >
                Remover
              </button>
            </div>
          ))}
          <button
            className="btn btn-block my-2 btn-sm btn-ghost"
            type="button"
            onClick={() => append({ file_name: "", file: null })}
          >
            Fazer upload de outro arquivo
          </button>
          <div className="grid grid-cols-3 gap-2 items-end">
            <button className="btn col-start-3">Upload</button>
          </div>
        </form>
        <div className="divider"></div>
        <ClientFileTable rows={tableFiles} update={updateFiles} />
      </div>
    </div>
  );
};

export default ClientFiles;
