import React, { createContext, useState, useEffect } from "react";
import PlanController from "Controllers/PlanController";
import { Product, ProductFormModel } from "utils/interfaces/forms/productForm";
import ProductController from "Controllers/ProductController";
import { useParams } from "react-router-dom";

interface TableContextProps {
  tableProduct: Product[];
  setTableProduct: (products: Product[]) => void;
  editableProduct: ProductFormModel | undefined;
  setEditableProduct: (product: ProductFormModel | undefined) => void;
  updateProducts: () => Promise<void>;
  getProduct: (id: string | number) => Promise<Product>;
}

export const ClientProductContext = createContext<
  TableContextProps | undefined
>(undefined);

export const ClientProductProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [editableProduct, setEditableProduct] = useState<ProductFormModel>();
  const [tableProduct, setTableProduct] = useState<Product[]>([]);
  const { id: client_id } = useParams();

  const updateProducts = async () => {
    if (client_id) {
      const plans = await ProductController.getByClientId(client_id);
      setTableProduct(plans);
    }
  };

  const getProduct = async (id: string | number) => {
    return await ProductController.show(id);
  };

  useEffect(() => {
    updateProducts();
  }, []);

  return (
    <ClientProductContext.Provider
      value={{
        tableProduct,
        setTableProduct,
        editableProduct,
        setEditableProduct,
        updateProducts,
        getProduct,
      }}
    >
      {children}
    </ClientProductContext.Provider>
  );
};
