import React from "react";
import ClienteTabs from "components/Tabs/ClienteTabs";
import ClientInvoicesTable from "components/Table/ClienteInvoicesTable";
import { InvoiceTableProvider } from "contexts/ClientInvoicesProvider";
import { modalRef } from "components/Modal/invoiceModal";

const ClientInvoices: React.FC = () => {
  return (
    <div className="card shadow-xl w-full bg-base-100">
      <div className="card-body">
        <ClienteTabs />
        <div className="w-full flex">
          <button
            className="btn ml-auto"
            onClick={() => modalRef().showModal()}
          >
            {" "}
            Adicionar
          </button>
        </div>
        <div className="divider"></div>
        <InvoiceTableProvider>
          <ClientInvoicesTable />
        </InvoiceTableProvider>
      </div>
    </div>
  );
};

export default ClientInvoices;
