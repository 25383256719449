import { ClientPlan } from "utils/interfaces";
import { ApiError } from "utils/interfaces/responses/error";
import { ApiResponse } from "utils/interfaces/responses/sucess";
import apiClient from "services/axios/CrmApi";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

export default class ClientPlanController {
  static defaultClientPlan: ClientPlan = { plans: [], total_value: 0 };

  static async getPlans(id: string | number): Promise<ClientPlan> {
    return toast
      .promise(apiClient.get(`/clients/plans/${id}`), {
        pending: "Atualizando Lista de Planos",
        success: {
          render({ data }) {
            return `${data.data.message}`;
          },
        },
        error: {
          render({ data }: { data: AxiosError<ApiError> }) {
            return `${data.response?.data.message}`;
          },
        },
      })
      .then(({ data }) => {
        if (data.data) {
          return data.data as ClientPlan;
        } else {
          return this.defaultClientPlan;
        }
      })
      .catch((error) => {
        console.log(error);
        return this.defaultClientPlan;
      });
  }

  static async attachPlan(
    plan_id: string | number,
    client_id: string,
    signature_date: string
  ) {
    return toast
      .promise(
        apiClient.post<ApiResponse<any>>(
          `/clients/${client_id}/plan/${plan_id}`,
          {
            signature_date: signature_date,
          }
        ),
        {
          pending: "Cadastrando Cliente",
          success: {
            render({ data }) {
              return `${data.data.message}`;
            },
          },
          error: {
            render({ data }: { data: ApiError }) {
              return `${data.message}`;
            },
          },
        }
      )
      .then(({ data }) => {
        if (data.data) {
          return data.data;
        } else {
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  }

  static async unlinkPlan(plan_id: string | number, client_id: string) {
    return toast
      .promise(
        apiClient.delete<ApiResponse<any>>(
          `/clients/${client_id}/plan/${plan_id}`
        ),
        {
          pending: "Removendo assinatura",
          success: {
            render({ data }) {
              return `${data.data.message}`;
            },
          },
          error: {
            render({ data }: { data: ApiError }) {
              return `${data.message}`;
            },
          },
        }
      )
      .then(({ data }) => {
        if (data.data) {
          return data.data;
        } else {
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  }
  static async relinkPlan(plan_id: string | number, client_id: string) {
    return toast
      .promise(
        apiClient.patch<ApiResponse<any>>(
          `/clients/${client_id}/plan/${plan_id}`
        ),
        {
          pending: "Reativando assinatura",
          success: {
            render({ data }) {
              return `${data.data.message}`;
            },
          },
          error: {
            render({ data }: { data: ApiError }) {
              return `${data.message}`;
            },
          },
        }
      )
      .then(({ data }) => {
        if (data.data) {
          return data.data;
        } else {
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  }
}
