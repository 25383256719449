import SearchModal, { getModalRef } from "components/Modal/searchModal";
import React, { useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import ThemeManager from "utils/ThemeManager";
import UserInformation from "./NavbarItens/UserInfo";

const Navbar: React.FC = () => {
  const [theme, setTheme] = useLocalStorage("theme", "light");

  const toggleTheme = () => {
    theme === "dark" ? setTheme("light") : setTheme("dark");
  };

  useEffect(() => {
    ThemeManager.setTheme(theme);
  }, [theme]);

  return (
    <div className="navbar top-bar">
      <div className="flex-none">
        <label
          htmlFor="menu-drawer"
          className="btn btn-square btn-ghost drawer-button lg:hidden"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="inline-block h-5 w-5 stroke-current"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </label>
      </div>

      <div className="flex-1">
        <button
          onClick={() => getModalRef().showModal()}
          className="btn hidden h-9 items-center min-w-72 justify-start gap-3 border-base-content/40 hover:border-transparent hover:bg-base-content/20 sm:flex btn-sm btn-outline"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-base-content/40"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
          <div className="text-base-content/50 mr-5">Pesquisar</div>
        </button>
      </div>

      <div className="flex-none">
        <nav
          aria-label="Secondary"
          className="hidden space-x-2 md:flex md:items-center"
        >
          <label className="grid cursor-pointer place-items-center">
            <input
              type="checkbox"
              value="dark"
              className="toggle theme-controller bg-base-content col-span-2 col-start-1 row-start-1"
              defaultChecked={theme === "dark"}
              onClick={toggleTheme}
            />
            <svg
              className="col-start-1 row-start-1  stroke-base-content fill-base-content"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="5" />
              <path d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4" />
            </svg>
            <svg
              className="stroke-base-content fill-base-content col-start-2 row-start-1"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
            </svg>
          </label>
        </nav>
      </div>
      <UserInformation />
      <SearchModal />
    </div>
  );
};

export default Navbar;
