import React from "react";
interface InputData {
  label: string;
  onClick: () => void;
}

const InlineLabelButton: React.FC<InputData> = ({ label, onClick }) => {
  return (
    <label className="form-controlinline text-center">
      <div className="label">
        <span className="label-text">{label}</span>
      </div>
      <button
        type="button"
        onClick={onClick}
        className="btn btn-square btn-outline mx-auto"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 stroke-base-content fill-base-content"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </label>
  );
};

export default InlineLabelButton;
