const ClientInvoiceHeader = () => {
  return (
    <thead>
      <tr>
        <th>#</th>
        <th>Nosso Nº</th>
        <th>Emissão</th>
        <th>Pagamento</th>
        <th>Cancelamento</th>
        <th>Status</th>
        <th>Valor R$</th>
        <th>Pago R$</th>
        <th>Ações</th>
      </tr>
    </thead>
  );
};

export default ClientInvoiceHeader;
