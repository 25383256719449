import { Client } from "./interfaces";

export class ClientCache {
  static getCache(): Client[] {
    const cachedData = localStorage.getItem("recentItems");
    return cachedData ? JSON.parse(cachedData) : [];
  }

  static saveCache(items: Client[]): void {
    localStorage.setItem("recentItems", JSON.stringify(items));
  }

  static addItemToCache(item: Client): void {
    const items = this.getCache();
    const existingIndex = items.findIndex(
      (cachedItem) => cachedItem.id === item.id
    );

    if (existingIndex !== -1) {
      items.splice(existingIndex, 1);
    }

    items.unshift(item);

    if (items.length > 5) {
      items.pop();
    }

    this.saveCache(items);
  }

  static getRecentItems(): Client[] {
    return this.getCache();
  }

  static clearCache(): void {
    localStorage.removeItem("recentItems");
  }
}
