const PlanHeader = () => {
  return (
    <thead>
      <tr>
        <th>#</th>
        <th>Nome</th>
        <th>Status</th>
        <th>Valor</th>
        <th>Acões</th>
      </tr>
    </thead>
  );
};

export default PlanHeader;
