export function parseMonetaryValueToNumber(value: string): number {
  const numericValue = value.replace(/[^\d,]/g, "").replace(",", ".");

  return parseFloat(numericValue);
}

export function formatNumberToMonetaryValue(value: number | string): string {
  try {
    const formattedValue = Number(value).toFixed(2).replace(".", ",");

    const parts = formattedValue.split(",");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return `R$ ${parts.join(",")}`;
  } catch (error) {
    return `${value}`;
  }
}
