import React, { forwardRef } from "react";
import { FieldError } from "react-hook-form";

interface InputData extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: FieldError;
  className?: string;
  required?: boolean;
}

const TextInput = forwardRef<HTMLInputElement, InputData>(
  ({ label, error, className, required, ...rest }, ref) => {
    return (
      <label className={`form-control w-full  ${className}`}>
        <div className="label">
          <span className="label-text">{label}</span>
          {required && <span className="label-text text-error">*</span>}
        </div>
        <input
          type="text"
          placeholder="Type here"
          className={`input input-md input-bordered w-full ${
            error && "input-error"
          }`}
          ref={ref}
          {...rest}
        />
      </label>
    );
  }
);

export default TextInput;
