import React from "react";
import { useParams } from "react-router-dom";
import ClienteTabs from "components/Tabs/ClienteTabs";
import ClientProductTable from "components/Table/ClientProductTable";
import { ClientProductProvider } from "contexts/ClientProductProvider";

const ClientProduct: React.FC = () => {
  const { id: client_id } = useParams();
  return (
    <div className="card shadow-xl w-full bg-base-100">
      <div className="card-body">
        <ClienteTabs id={client_id} />
        <ClientProductProvider>
          <ClientProductTable />
        </ClientProductProvider>
      </div>
    </div>
  );
};

export default ClientProduct;
