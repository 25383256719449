import React from "react";

const LoadingScreen: React.FC = () => {
  return (
    <div className="h-full w-full bg-neutral text-neutral-content text-6xl items-center flex justify-center">
      Carregando...
    </div>
  );
};

export default LoadingScreen;
