import { ClientContactType, ClientDocumentType } from "utils/Enums/Client";
import { Client } from "../interfaces";
import { removeNonNumeric } from "./Documents";

export interface ClientFormModel {
  id?: number;
  document?: string;
  documentType?: string;
  name: string;
  fantasyName: string;

  cityId?: string;
  stateId?: string;

  street?: string;
  district?: string;

  number?: string;
  zip?: string;

  complement?: string;
  nearby?: string;

  contact: {
    id?: number;
    contact: string;
    typeContact: string;
  }[];

  due_day?: string;
  info?: string;
  domainId: string;
}

export const transformClientToFormModel = (client: Client): ClientFormModel => {
  return {
    id: client.id,
    document: client.document,
    documentType: client.document_type ? String(client.document_type) : "",
    name: client.name,
    fantasyName: client.fantasy_name,
    cityId: client.address?.city_id ? String(client.address?.city_id) : "",
    stateId: client.address?.city_id
      ? String(client.address?.city.state_id)
      : "",
    street: client.address?.street,
    district: client.address?.district,
    number: client.address?.number,
    zip: client.address?.zip,
    complement: client.address?.complement,
    nearby: client.address?.nearby,
    contact: client.contacts.map((contact) => ({
      id: contact.id,
      contact: contact.contact,
      typeContact: String(contact.type),
    })),
    info: client.info,
    domainId: "",
    due_day: client.due_day,
  };
};

export const transformCNPJApiToFormModel = (
  enterprise: Empresa
): ClientFormModel => {
  const contacts = [];

  if (enterprise.estabelecimento.telefone1) {
    contacts.push({
      contact: `${enterprise.estabelecimento.ddd1}${enterprise.estabelecimento.telefone1}`,
      typeContact: ClientContactType.Mobile,
    });
  }

  if (enterprise.estabelecimento.telefone2) {
    contacts.push({
      contact: `${enterprise.estabelecimento.ddd2}${enterprise.estabelecimento.telefone2}`,
      typeContact: ClientContactType.Mobile,
    });
  }

  if (enterprise.estabelecimento.email) {
    contacts.push({
      contact: enterprise.estabelecimento.email,
      typeContact: ClientContactType.Email,
    });
  }

  return {
    document: enterprise.estabelecimento.cnpj,
    documentType: ClientDocumentType.JURIDICA,
    name: enterprise.socios[0].nome,
    fantasyName: enterprise.razao_social,
    street: enterprise.estabelecimento.logradouro,
    district: enterprise.estabelecimento.bairro,
    number: enterprise.estabelecimento.numero,
    zip: enterprise.estabelecimento.cep,
    complement: enterprise.estabelecimento.tipo_logradouro,
    nearby: enterprise.estabelecimento.complemento,
    contact: contacts,
    domainId: "",
    info: "",
    stateId: String(enterprise.estabelecimento.estado.nome),
    cityId: String(enterprise.estabelecimento.cidade.nome),
    due_day: "5",
  };
};

export const transformFormModelToRequest = (
  clientFormModel: ClientFormModel
) => {
  return {
    id: clientFormModel.id,
    document:
      clientFormModel.document && removeNonNumeric(clientFormModel.document),
    document_type: clientFormModel.documentType,
    name: clientFormModel.name,
    fantasy_name: clientFormModel.fantasyName,
    address: {
      street: clientFormModel.street,
      number: clientFormModel.number,
      complement: clientFormModel.complement,
      district: clientFormModel.district,
      nearby: clientFormModel.nearby,
      zip: clientFormModel.zip && removeNonNumeric(clientFormModel.zip),
      city_id: clientFormModel.cityId,
      state_id: clientFormModel.stateId,
    },
    contacts: clientFormModel.contact.map(({ contact, typeContact }) => ({
      contact,
      type: typeContact,
    })),
    info: clientFormModel.info,
    domainId: clientFormModel.domainId,
    due_day: clientFormModel.due_day ? Number(clientFormModel.due_day) : null,
  };
};
