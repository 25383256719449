import Dropdown from "components/Dropdown/dropdown";
import { InvoiceTableContext } from "contexts/ClientInvoicesProvider";
import React, { useContext } from "react";
import { Invoice } from "utils/interfaces/Invoices";
interface RowData {
  row: Invoice;
}

const ClientInvoicesRow: React.FC<RowData> = ({ row }) => {
  const {} = useContext(InvoiceTableContext)!;

  function formatDate(apiDate: string) {
    const date = new Date(apiDate);
    return date.toLocaleString("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  }
  return (
    <React.Fragment key={row.id}>
      <tr className="hover cursor-pointer">
        <th>{row.id}</th>
        <td className="whitespace-nowrap">{row.our_number}</td>
        <td>{formatDate(row.created_at)}</td>
        <td>{formatDate(row.date_due)}</td>
        <td>{row.date_canceled ? formatDate(row.date_canceled) : "- -"}</td>
        <td>{row.state}</td>
        <td>{row.value}</td>
        <td>{row.value_paid}</td>
        <td>
          <Dropdown title="Ações">
            <ul>
              <li>
                <a>Visualizar</a>
              </li>
              <li>
                <a>Editar</a>
              </li>
              <li>
                <a>Acessar PDF</a>
              </li>
              <li>
                <a>Cancelar</a>
              </li>
              <li>
                <a>Notificar</a>
              </li>
              <li>
                <a>Copiar linha digitável</a>
              </li>
              <li>
                <a>Copiar PIX</a>
              </li>
            </ul>
          </Dropdown>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default ClientInvoicesRow;
