import { Link } from "react-router-dom";

type MenuItem = {
  label: string;
  icon?: React.ReactNode;
  submenu?: MenuItem[];
  href?: string;
  disabled?: boolean;
};

const MenuGroup: React.FC<MenuItem> = ({ label, icon, submenu, disabled }) => {
  return (
    <ul className="menu rounded-box px-0 text-base-content stroke-base-content fill-base-content">
      <li>
        <details>
          <summary
            className="list-none [&::-webkit-details-marker]:hidden [&::marker]:hidden
                px-4 py-2 text-start grid grid-flow-col content-start items-center auto-cols-[minmax(auto,_max-content)_auto_max-content]
                gap-2 rounded-lg"
          >
            {icon && icon}
            {label}
          </summary>
          <ul>
            {submenu &&
              submenu.map(
                (subItem, subIndex) =>
                  subItem.href && (
                    <li key={`${subItem.href}-${subIndex}`}>
                      <Link to={subItem.href}>{subItem.label}</Link>
                    </li>
                  )
              )}
          </ul>
        </details>
      </li>
    </ul>
  );
};
export default MenuGroup;
