import { AxiosError } from "axios";
import { toast } from "react-toastify";
import apiClient from "services/axios/CrmApi";
import { ClientFile } from "utils/interfaces";
import { ApiError } from "utils/interfaces/responses/error";

export default class FilesController {
  static defaultClientFiles: ClientFile[] = [];

  static async uploadFiles(client_id: number | string, data: FormData) {
    return toast
      .promise(
        apiClient.post(`/clients/${client_id}/files/upload`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }),
        {
          pending: "Fazendo upload ...",

          error: {
            render({ data }: { data: ApiError }) {
              return `${data.message}`;
            },
          },
        }
      )
      .catch((error) => {
        console.log(error);
      });
  }

  static async getFiles(id: string | number): Promise<ClientFile[]> {
    return toast
      .promise(apiClient.get(`/clients/${id}/files`), {
        pending: "Atualizando Lista de Arquivos",
        success: "Lista de Arquivos Atualizada",

        error: {
          render({ data }: { data: AxiosError<ApiError> }) {
            return `${data.response?.data.message}`;
          },
        },
      })
      .then(({ data }) => {
        if (data.data) {
          return data.data;
        } else {
          return this.defaultClientFiles;
        }
      })
      .catch((error) => {
        console.log(error);
        return this.defaultClientFiles;
      });
  }

  static async deleteFiles(id: string | number, file_id: string | number) {
    return toast
      .promise(apiClient.delete(`/clients/${id}/files/${file_id}`), {
        pending: "Atualizando Lista de Arquivos",
        success: "Lista de Arquivos Atualizada",

        error: {
          render({ data }: { data: AxiosError<ApiError> }) {
            return `${data.response?.data.message}`;
          },
        },
      })
      .then((response) => {
        if (response.data) {
          return response.data;
        } else {
          return this.defaultClientFiles;
        }
      })
      .catch((error) => {
        console.log(error);
        return this.defaultClientFiles;
      });
  }

  static async getDownloadLink(
    id: string | number,
    file_id: string | number,
    file_name?: string
  ) {
    return toast
      .promise(
        apiClient.get(`/clients/${id}/files/${file_id}/download`, {
          responseType: "blob",
        }),
        {
          pending: "Baixando arquivo",

          error: {
            render({ data }: { data: ApiError }) {
              return `${data.message}`;
            },
          },
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;

        const contentDisposition = response.headers["content-disposition"];
        const fileName = file_name
          ? file_name
          : contentDisposition
          ? contentDisposition.split("filename=")[1].replace(/['"]/g, "")
          : "downloaded-file";

        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
